import React, { useState, useEffect } from 'react';
import { Input, Card, Button, Tag, Space, Typography, Tooltip, Divider } from 'antd';
import { SearchOutlined, LeftOutlined, RightOutlined, FolderOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import QuickSearch from '../QuickSearch';
import { Tutorial } from '../Tutorial';
import {BellFilled, CaretDownFilled, CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons";


const { Title, Text } = Typography;
const styles = {
    transitionContainer: {
        transition: 'all 0.3s ease-in-out',
        position: 'relative',
    },
    closeButton: {
        position: 'fixed',
        bottom: '50px',
        right: '30px',
        background: 'transparent',
        border: 'blue 1px',
        cursor: 'pointer',
        borderRadius:'50%',
        zIndex: 1000,
    },
};

const GeneralGuide = () => {
    const [visible, setVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [hoveredItem, setHoveredItem] = useState(null);
    const itemsPerPage = 8;
    const history = useHistory();
    const [isQuickSearchHovered, setIsQuickSearchHovered] = useState(false);
    const {show, setShow} = useState(false)
    const [toggle, setToggle] = useState(false);
    const showAlert = (value) => {
        setShow(value)
    }
    const handleToggle = () => {
        setToggle(!toggle);
    };

    return (
        <>
            <div className={`transition-container ${toggle ? 'visible' : 'hidden'}`}>
                <QuickSearch />
                <Tutorial className="m-8" />
            </div>
            <Button 
                onClick={(event) => {
                    event.preventDefault();
                    setToggle(!toggle);
                }}
                className="close-button" 
                style={styles.closeButton} 
                shape="circle"
                type="button"
            >
                <QuestionCircleOutlined 
                    style={{
                        width: '32px',
                        height: '32px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                        transition: 'all 0.3s',
                        transform: `rotate(${toggle ? 0 : 180}deg)`,
                    }}
                />
            </Button>
        </>
    );
};

export default GeneralGuide;