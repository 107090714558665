import React, {useState, useRef} from "react";


import App from "../../routes/index";


import {useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Badge, Button, Layout} from "antd";
import {footerText} from "../../util/config";
import Sidebar from "../Sidebar";
import AlertNotification from "../Alert";
import {BellFilled, CaretDownFilled, CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import CompanyDrawer from "../../components/CompanyDrawer";
import {openCompanyDrawer} from "../../appRedux/actions";
import {DOCUMENT_BASE_URL} from "../../constants/ServerUrl";
import {NoPageAccess} from "../../components/NoPageAccess";
import GeneralGuide from "../GeneralGuide";
const {Content, Header, Footer} = Layout;
const MainApp = () => { 
    let [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const {activeCompany} = useSelector(({company}) => company);
    const {authUser} = useSelector(({auth}) => auth);
    const {show, setShow} = useState(false)
    const alert = "😊 Hey Champ!, Great work so far. Just a friendly reminder to checkout before leaving. Cheers! 👍"
    const alert2 = "Good morning Champ!, Top of the morning to you. Grab a cup of coffee ☕️ , Dont forget to Check in and Lets perform magic! 👍"
    const [isQuickSearchHovered, setIsQuickSearchHovered] = useState(false);
    //const showComponents = useRef(false);
    const [toggle, setToggle] = useState(false);

    const showAlert = (value) => {
        setShow(value)
    }

    const handleToggle = () => {
        setToggle(!toggle);
    };

    return (
        <Layout className="">
            <Sidebar sidebarCollapsed={sidebarCollapsed}/>
            <Layout>
                
                {/* <AlertNotification message={alert}  hour="15" minute="50"> </AlertNotification> */}
                <AlertNotification message={alert2}  hour="21" minute="42"> </AlertNotification>
                
                <Header className="bg-white p-0 h-20">
                    {/*<div className="px-3 py-5 text-2xl cursor-pointer " onClick={() => {*/}
                    {/*    setSidebarCollapsed(!sidebarCollapsed);*/}
                    {/*}}>*/}

                    {/*    {sidebarCollapsed ? (<MenuUnfoldOutlined/>) : (<MenuFoldOutlined/>)}*/}

                    {/*</div>*/}
                    {activeCompany && (
                        <div className="flex  justify-end space-x-20 mt-2 mr-24">
                            <div>
                                <Badge dot>
                                    <BellFilled/>
                                </Badge>
                            </div>

                            {activeCompany.company_logo_path && (
                                <div className="flex cursor-pointer" onClick={() => {
                                    dispatch(openCompanyDrawer());
                                }}>
                                    <div className=" rounded-full  p-1 ">

                                        <img width="50" className="rounded-full" alt={activeCompany.business_name}
                                             src={`${DOCUMENT_BASE_URL}/${activeCompany.company_logo_path}`}/>
                                    </div>
                                    <div>

                                        <p className="pl-3 font-semibold">{activeCompany.business_name} <CaretDownFilled
                                            className="pl-4"/></p>
                                    </div>
                                </div>
                            )}
                            {!activeCompany.company_logo_path && (
                                <div className="flex cursor-pointer " onClick={() => {
                                    dispatch(openCompanyDrawer());
                                }}>
                                    <div className=" rounded-full  p-1 ">

                                        <img width="50" className="rounded-full" alt="POSNL"
                                             src="/images/app-icons/placeholder.jpg"/>
                                    </div>
                                    <div>

                                        <p className=" pl-3 font-semibold">{activeCompany.business_name}
                                            <CaretDownFilled
                                                className=" pl-4"/></p>
                                    </div>
                                </div>)}
                        </div>)}
                    {!activeCompany && (
                        <div className="flex  justify-end space-x-20 mt-2 mr-24">
                            <div>

                                <Badge dot>
                                    <BellFilled/>
                                </Badge>
                            </div>
                            <div className="flex cursor-pointer " onClick={() => {
                                dispatch(openCompanyDrawer());
                            }}>
                                <div className=" rounded-full  p-1 ">

                                    <img width="50" className="rounded-full" alt="Phillips Outsourcing"
                                         src="/images/app-icons/placeholder.jpg"/>
                                </div>
                                <div>

                                    <p className=" pl-3 font-semibold">Select a Company<CaretDownFilled
                                        className=" pl-4"/></p>
                                </div>
                            </div>
                        </div>

                    )}
                </Header>
                <Content className="overflow-auto h-screen">
                    <CompanyDrawer/>
                    <GeneralGuide/>
                
                    {authUser && (
                        <>
                            {(authUser.employee_status_id.toString() !== "1") ? (
                                <NoPageAccess/>
                            ) : (<App match={match}/>)}
                        </>
                    )}
                    <Footer>
                        <div className="gx-layout-footer-content">
                            {footerText}
                        </div>
                    </Footer>
                   
                </Content>
                
            </Layout>
            
        </Layout>
    )
};

const styles = {
    transitionContainer: {
        transition: 'all 0.3s ease-in-out',
        position: 'relative',
    },
    closeButton: {
        position: 'fixed',
        bottom: '50px',
        right: '30px',
        background: 'transparent',
        border: 'blue 1px',
        cursor: 'pointer',
        borderRadius:'50%',
        zIndex: 1000,
    },
};

export default MainApp;

